import React from 'react'
import PropTypes from 'prop-types'
import { default as NextLink } from 'next/link'

import { getFullUrl, getNextLink } from '../../../utils'
import { useTranslation } from '../../../utils/providers'

/**
 * We have 3 different use-cases for handling links:
 * 1) A link to another internal route. These are mostly project-specific (like rendering the user's cart)
 * 2) Links to external pages
 * 3) The most common: Links to other pages that get served by the main catch-all route
 */
function Link({ href, children, ...rest }) {
  const { language } = useTranslation()
  const target = Array.isArray(href) ? href[0] : href
  const { fullUrl, isExternalLink } = getFullUrl(target)

  /**
   * External Pages
   */
  if (isExternalLink) {
    // Because we are tracking to many direct visits, we remove the makaira security solution to add `rel="noopener noreferrer"`
    return (
      <a href={fullUrl} target="_self" {...rest}>
        {children}
      </a>
    )
  }

  /**
   * Pages served by catch-all route
   */
  const { internalUrl, externalUrl } = getNextLink(fullUrl, language)

  return (
    <NextLink href={internalUrl} as={externalUrl}>
      <a {...rest}>{children}</a>
    </NextLink>
  )
}

Link.propTypes = {
  href: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  children: PropTypes.any,
}

export default Link
