/**
 * Get the default language key from the '.env' file. Defaults to 'en'
 *
 * @returns {string}
 */
export default function getDefaultLanguage() {
  const fallback = 'en'
  const defaultLang = process.env.LOCALE?.substring(0, 2).toLowerCase()
  return defaultLang || fallback
}
