import german from './locales/de'
import english from './locales/en'

const translations = {
  de: {
    ...german,
  },
  en: {
    ...english,
  },
}

/**
 * Translate a string into the given language.
 *
 * <b>Warning:</b> Direct use of this function should be avoided in components,
 * as the language may be switched on-the-fly.
 * Use the {@link TranslationProvider} instead.
 *
 * @param {string} key
 * @param {string} language
 * @returns {string} The translated key if it exists, or the key itself otherwise
 */
function translate(key, language) {
  if (key && language) {
    return translations[language]?.[key] || key
  }
  return key
}

export default translations
export { translate }
