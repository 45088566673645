/**
 * The Common.js export here is intentional since
 * we need to import this file in server.js as well
 * and Next.js currently does not support ESM modules.
 */
const modulesList = [
  '/customer/account',
  '/customer/account/confirm',
  '/customer/account/edit',
  '/customer/account/resetpassword/',
  '/customer/account/logout',
  '/sales/order/history',
  '/sales/order/view',
  '/customer/account/contact',
  '/checkout/onepage/success',
  '/checkout/shopify/success',
  '/checkouttester/index/success',
  '/paypal/express/review',
  '/payment/addresscorrection',
  '/checkout/cart',
  '/cartUpdate/abodelivery/view',
  '/reorder/category/view',
  '/newsletter-subscribe/token/:token/id/:id',
  '/newsletter-unsubscribe/token/:token/id/:id',
  '/newsletter-optout',
  '/404',
]

module.exports = modulesList
