import React, { Component, useContext } from 'react'

import { translate } from '../..'

import TranslationComponent from './TranslationComponent'
import TranslatedOption from './TranslatedOption'
import getDefaultLanguage from './getDefaultLanguage'

const TranslationContext = React.createContext()

class TranslationProvider extends Component {
  static defaultProps = { language: getDefaultLanguage() }

  state = {
    language: this.props.language,
  }

  translate = (key, fallback = '') => {
    return translate(key, this.state.language) ?? fallback
  }

  setLanguage = (language) => {
    this.setState({ language }, () =>
      dispatchEvent(new CustomEvent('change:language'))
    )
  }

  render() {
    return (
      <TranslationContext.Provider
        value={{
          language: this.state.language,
          t: this.translate,
          setLanguage: this.setLanguage,
        }}
      >
        {this.props.children}
      </TranslationContext.Provider>
    )
  }
}

function useTranslation() {
  return useContext(TranslationContext)
}

function withTranslation(Component) {
  return function WithTranslation(props) {
    const ctx = useTranslation()
    return <Component {...ctx} {...props} />
  }
}

export default TranslationContext
export {
  TranslationProvider,
  TranslationComponent,
  getDefaultLanguage,
  useTranslation,
  withTranslation,
  TranslatedOption,
}
