import * as React from 'react'
import debounce from '../../core/debounce'
import { Component, useContext } from 'react'

const BREAKPOINTS_UP = {
  upXL: { minWidth: 1200 },
  upLG: { minWidth: 992 },
  upMD: { minWidth: 768 },
  upSM: { minWidth: 576 },
  upXS: { minWidth: 320 },
}
const BREAKPOINTS_DOWN = {
  downXL: { maxWidth: 1199.98 },
  downLG: { maxWidth: 991.98 },
  downMD: { maxWidth: 767.98 },
  downSM: { maxWidth: 575.98 },
  downXS: { maxWidth: 319.98 },
}
// Default mode: desktop
const DEFAULT_BREAKPOINTS = {
  upXL: true,
  upLG: true,
  upMD: true,
  upSM: true,
  upXS: true,
  downXL: false,
  downLG: false,
  downMD: false,
  downSM: false,
  downXS: false,
}
// Any screen-width (not browser-width!) below this is considered a mobile device
const DEVICE_BREAKPOINT_QUERY = '(max-device-width: 1023.98px)'

/**
 * Helper method that performs window-size/breakpoint comparisons.
 */
function determineBreakpoints() {
  // When server-side-rendering, there is no "window" object
  const isSSR = typeof window === 'undefined'

  // Defaults
  const queryList = {
    matches: false,
  }
  const breakpoints = DEFAULT_BREAKPOINTS
  let windowWidth

  // When server-side-rendering, we assume the default mode
  if (!isSSR) {
    queryList.matches = window.matchMedia(DEVICE_BREAKPOINT_QUERY).matches
    Object.entries(BREAKPOINTS_UP).map(([key, entry]) => {
      breakpoints[key] = entry.minWidth <= window.innerWidth
    })
    Object.entries(BREAKPOINTS_DOWN).map(([key, entry]) => {
      breakpoints[key] = entry.maxWidth >= window.innerWidth
    })
    windowWidth = window.innerWidth
  }

  return {
    isSSR: isSSR,
    isMobile: queryList.matches,
    breakpoints: breakpoints,
    windowWidth,
  }
}

const ResizeContext = React.createContext()

class ResizeProvider extends Component {
  state = determineBreakpoints() // initial call (before the component gets mounted!)

  componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize, false)
    window.addEventListener('orientationchange', this.handleResize, false)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize, false)
    window.removeEventListener('orientationchange', this.handleResize, false)
  }

  // resize fires a lot of events, so let's reduce the number of function calls
  handleResize = debounce(() => this.doHandleResize(), 100)

  doHandleResize = () => {
    this.setState(determineBreakpoints())
  }

  render() {
    const value = {
      ...this.state,
      initialDevice: this.props.initialDevice,
    }
    return (
      <ResizeContext.Provider value={value}>
        {this.props.children}
      </ResizeContext.Provider>
    )
  }
}

// Hook that lets you watch for changes
function useMediaResize() {
  return useContext(ResizeContext)
}

export default ResizeContext
export { ResizeProvider, useMediaResize, determineBreakpoints }
export { default as MediaResize } from './MediaResize'
