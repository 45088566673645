import React from 'react'
import classNames from 'classnames'

function Text(props) {
  const {
    size = '',
    weight = '',
    align = '',
    color = '',
    underline = false,
    className = '',
    element = 'span',
    text,
    ...rest
  } = props

  const Element = element

  const classes = classNames(className, {
    [`font-size-${size}`]: size,
    [`font-weight-${weight}`]: weight,
    [`text-${align}`]: align,
    [`text-${color}`]: color,
    ['text-underline']: underline,
  })

  return (
    <Element className={classes} {...rest}>
      {text}
    </Element>
  )
}

export default Text
export { default as textVariants } from './variants.js'
