import staticRoutes from '../../../frontend/staticroutes/routelist'
import allLanguages from '../../../config/allLanguages'

const isSearchRoute = (pathname, language) =>
  pathname === allLanguages.find(({ value }) => value === language)?.searchRoute

/**
 * Takes a URL and returns the internal NextJS route as well as a sanitized
 * version of the given URL. Sanitation includes removing leading double-
 * slashes and trailing slashes from the path.
 *
 * @param {string} fullUrl The full URL, e.g. after {@link getFullUrl}
 * @param {string} language The current language, in order to determine the search route
 */
export default function getNextLink(fullUrl, language) {
  const urlInstance = new URL(fullUrl)

  // Remove leading double-slashes and trailing slashes from absolute paths
  const pathname = urlInstance.pathname
    ?.replace(/^\/\//, '/')
    .replace(/(?!^)\/$/, '')
  const search = urlInstance.search.replace(/^\?/, '')
  const hash = urlInstance.hash

  // Check if it's a static page, search page or served by catch-all
  const isStatic = staticRoutes.some((route) => route === pathname)
  const isSearch = isSearchRoute(pathname, language)

  const internalUrl = `/frontend/${
    isStatic ? 'static' : isSearch ? 'search' : 'entry'
  }?seoUrl=${pathname}${search !== '' ? `&${search}` : ''}`
  const externalUrl = pathname + (search !== '' ? `?${search}` : '') + hash

  return { internalUrl, externalUrl }
}
